<template lang="pug">
div
  om-heading.mb-3(h3) {{ $t('experimental.flashingBrowserTab.heading') }}
  .row.mt-4.no-gutters
    .col-12.col-lg-4
      om-switch#browserTab(
        v-model.sync="active"
        @switch="toggleActive()"
        :label="$t('experimental.flashingBrowserTab.toggle')"
      )
  .row.no-gutters
    .col-12.col-lg-4
      om-switch#useCookieTargeting(
        :label="$t('experimental.flashingBrowserTab.useCookieTargeting')"
        v-model.sync="cookieTargetActive"
        :disabled="!active"
      )
  .row.px-2.mt-2
    .d-flex.mt-2.flex-column
      om-input#notificationName.w-20(
        :disabled="!cookieTargetActive"
        v-model="cookieTarget"
        :label="$t('experimental.flashingBrowserTab.cookieTargeting')"
        :required="false"
      )
      .input-alt-title.mb-4.w-20 {{ $t(`experimental.flashingBrowserTab.cookieTargetingAltTitle`) }}
  .row.px-2
    .d-flex.flex-column
      om-input#notificationName.w-20(
        v-model="browserTabText"
        :label="$t('experimental.flashingBrowserTab.inputLabel')"
        :required="false"
      )
      .input-alt-title.mb-4 {{ $t(`experimental.flashingBrowserTab.inputAltTitle`) }}
  .row.px-2.mt-2
    om-button(primary small @click="save") {{ $t('save') }}
</template>
<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return { active: false, cookieTargetActive: false, browserTabText: '', cookieTarget: '' };
        },
      },
    },
    data: () => ({
      active: false,
      cookieTargetActive: false,
      browserTabText: '',
      cookieTarget: '',
    }),
    created() {
      if (this.data) {
        const { active, cookieTargetActive, browserTabText, cookieTarget } = this.data;
        this.active = active;
        this.cookieTargetActive = cookieTargetActive || false;
        this.browserTabText = browserTabText;
        this.cookieTarget = cookieTarget;
      }
    },
    methods: {
      toggleActive() {
        if (!this.active) {
          this.cookieTargetActive = false;
        }
      },
      save() {
        this.$emit('add', {
          active: this.active,
          cookieTargetActive:
            this.cookieTargetActive && this.cookieTarget ? this.cookieTargetActive : false,
          browserTabText: this.browserTabText,
          cookieTarget: this.cookieTarget,
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors.sass'
  .toggle-text
    font-size: 12px
    line-height: 24px
    color: $om-gray-700
    display: flex
    align-items: center
  .cookie-target
    min-width: 149px
  .input-alt-title
    font-size: 12px
    line-height: 16px
    color: $om-gray-600
</style>
